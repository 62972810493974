import {
  faTimes,
  faTrash,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { Schedule } from 'data/calendarEvents';
import dayjs from 'dayjs';
import { useCalendarContext } from 'providers/CalendarProvider';
import { Modal, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { REMOVE_EVENT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FoodPaichart from './FoodPaichart';

const CalendarEventModal = () => {
  const { selectedEvent, calendarDispatch } = useCalendarContext();
  const [events, setEvents] = useState(selectedEvent ? selectedEvent : null);
  useEffect(() => {
    setEvents(selectedEvent);
  }, [selectedEvent]);

  const [modalName, setModalName] = useState('info-modal');
  const { id } = useParams();
  const token = localStorage.getItem('token');

  const handleClose = () => {
    calendarDispatch({
      type: SET_CALENDAR_STATE,
      payload: {
        selectedEvent: null
      }
    });
  };

  const handleRemove = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}/meal/${selectedEvent?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      calendarDispatch({
        type: REMOVE_EVENT
      });
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleCloseEditInfoModal = async () => {
    setModalName('info-modal');
  };

  const handleEdit = async () => {
    setModalName('edit-info-modal');
  };

  const updateMeal = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_ENDPOINT}/clinic/meal`,
        {
          patientID: id,
          mealID: selectedEvent?.id,
          nutrient: events?.extendedProps.nutrient
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      selectedEvent?.setExtendedProp(
        'nutrient',
        events?.extendedProps.nutrient
      );
      handleCloseEditInfoModal();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const handleNutrientChange = async (index: number, newQuantity: any) => {
    setEvents((events: any) => {
      const updatedNutrients = events?.extendedProps.nutrient.map(
        (nutrient: any, i: number) =>
          i === index ? { ...nutrient, quantity: newQuantity } : nutrient
      );

      return {
        ...events,
        extendedProps: {
          ...events.extendedProps,
          nutrient: updatedNutrients
        }
      };
    });
  };

  return (
    <>
      <Modal
        centered
        show={!!selectedEvent && modalName == 'info-modal'}
        onHide={handleClose}
        contentClassName="border"
        size="xl"
      >
        <Modal.Header className="ps-card border-bottom border-translucent">
          <div>
            <Modal.Title className="modal-title text-body-highlight mb-0">
              {selectedEvent?.title}
            </Modal.Title>
            {selectedEvent?.extendedProps.organizer && (
              <p className="mb-0 fs-9 mt-1">
                by <Link to="#!">{selectedEvent.extendedProps.organizer}</Link>
              </p>
            )}
          </div>
          <Button className="p-1" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-card pb-card pt-1 fs-9">
          <Row>
            <Col sm={3} md={3}>
              <img
                src={selectedEvent?.extendedProps.mealFile}
                alt=""
                width="100%"
              />
            </Col>
            <Col sm={4} md={4}>
              <div>
                <div
                  className={classNames('mt-4', {
                    'border-bottom border-translucent pb-3':
                      selectedEvent?.extendedProps.location
                  })}
                >
                  <h5 className="mb-0 text-body-secondary">Date and Time</h5>
                  <p className="mb-1 mt-2">
                    {dayjs(selectedEvent?.extendedProps.createdAt).format(
                      'dddd, MMMM D, YYYY, h:mm A'
                    )}
                    {selectedEvent?.end &&
                      ` – ${
                        dayjs &&
                        dayjs(selectedEvent?.end)
                          .subtract(1, 'day')
                          .format('dddd, MMMM D, YYYY, h:mm A')
                      }`}
                  </p>
                </div>
                {selectedEvent?.extendedProps.description && (
                  <div className="mt-3 border-bottom border-translucent pb-3">
                    <h5 className="mb-0 text-body-secondary">Description</h5>
                    <p className="mb-0 mt-2">
                      {selectedEvent.extendedProps.description
                        .split(' ')
                        .slice(0, 30)
                        .join(' ')}
                    </p>
                  </div>
                )}
                <div
                  className={classNames('mt-4', {
                    'border-bottom border-translucent pb-3':
                      selectedEvent?.extendedProps.location
                  })}
                >
                  <h5 className="mb-0 text-body-secondary">Items</h5>
                  <div className="mb-1 mt-2">
                    {selectedEvent?.extendedProps.nutrient ? (
                      <ol>
                        {selectedEvent?.extendedProps.nutrient.map(
                          (nutrient: any, i: number) => (
                            <li key={i}>
                              <h5>
                                {nutrient.name} {nutrient.quantity}g
                              </h5>
                              <ul className="mb-1 mt-2">
                                {nutrient?.nutritionFact.map(
                                  (fact: any, i: number) => (
                                    <li key={i}>
                                      {fact.nutrientName}: {fact.value}
                                      {fact.unitName}:
                                    </li>
                                  )
                                )}
                              </ul>
                            </li>
                          )
                        )}
                      </ol>
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={5} md={5}>
              {selectedEvent?.extendedProps.nutrient ? (
                <FoodPaichart
                  nutrient={selectedEvent?.extendedProps.nutrient}
                />
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end px-card pt-0 border-top-0">
          <Button
            onClick={() => handleEdit()}
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faPencilAlt} className="fs-10" />}
          >
            Edit
          </Button>
          <Button
            onClick={() => handleRemove()}
            variant="phoenix-danger"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faTrash} className="fs-9" />}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        show={modalName == 'edit-info-modal'}
        onHide={handleCloseEditInfoModal}
        contentClassName="border"
        size="lg"
      >
        <Modal.Header className="ps-card border-bottom border-translucent">
          <div>
            <Modal.Title className="modal-title text-body-highlight mb-0">
              {selectedEvent?.title}
            </Modal.Title>
            {selectedEvent?.extendedProps.organizer && (
              <p className="mb-0 fs-9 mt-1">
                by <Link to="#!">{selectedEvent.extendedProps.organizer}</Link>
              </p>
            )}
          </div>
          <Button className="p-1" onClick={handleCloseEditInfoModal}>
            <FontAwesomeIcon icon={faTimes} className="fs-8" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-card pb-card pt-1 fs-9">
          <div>
            <div
              className={classNames('mt-4', {
                'border-bottom border-translucent pb-3':
                  selectedEvent?.extendedProps.location
              })}
            >
              <div className="mb-1 mt-2">
                {events?.extendedProps.nutrient ? (
                  <>
                    {events?.extendedProps.nutrient.map(
                      (nutrient: any, i: number) => (
                        <Row className="mb-3" key={i}>
                          <Col>
                            <h5> {nutrient.name} </h5>
                          </Col>
                          <Col>
                            <input
                              type="number"
                              className="form-control"
                              value={nutrient.quantity}
                              onChange={e =>
                                handleNutrientChange(i, e.target.value)
                              }
                            />
                          </Col>
                        </Row>
                      )
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end px-card pt-0 border-top-0">
          <Button
            onClick={() => updateMeal()}
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faPencilAlt} className="fs-10" />}
          >
            Save
          </Button>
          <Button
            onClick={handleCloseEditInfoModal}
            variant="phoenix-danger"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faTrash} className="fs-9" />}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CalendarEventModal;
