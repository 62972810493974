import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clinicValidationSchma } from 'static/Clinic';
import * as yup from 'yup';
import QRCode from 'qrcode';

const SAAddClinic = () => {
  const navigate = useNavigate();
  const { Formik } = formik;
  const token = localStorage.getItem('token');

  const schema = yup.object().shape(clinicValidationSchma);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const onSubmit = async (values: any) => {
    const data = {
      clinic: {
        name: values.name,
        email: values.email,
        code: values.code,
        phone: values.phone,
        address: values.address,
        postcode: values.postcode
      },
      user: {
        firstname: values.name,
        email: values.email,
        password: values.password
      }
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ENDPOINT}/clinic/sa`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data['status'] == false) {
        toast.error(response.data['error']['message']);
        return false;
      }

      toast(response.data['data']['message']);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/superadmin/clinic/list');
  };

  return (
    <Card className="col-12">
      <Card.Header>
        <div className="d-sm-flex justify-content-between">
          <h2>Create a new clinic</h2>
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={12}>
            <Formik
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={values => onSubmit(values)}
              initialValues={{
                name: '',
                email: '',
                password: '',
                code: '',
                address: '',
                phone: '',
                postcode: ''
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                errors,
                dirty,
                setFieldValue
              }) => {
                const generatePassword = (length = 12) => {
                  const charset =
                    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
                  let password = '';
                  for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(
                      Math.random() * charset.length
                    );
                    password += charset[randomIndex];
                  }
                  setFieldValue('password', password);
                };

                const generateCode = async (length = 6) => {
                  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
                  let code = '';
                  for (let i = 0; i < length; i++) {
                    const randomIndex = Math.floor(
                      Math.random() * charset.length
                    );
                    code += charset[randomIndex];
                  }

                  setQrCodeImage(await QRCode.toDataURL(code));

                  setFieldValue('code', code);
                };
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row className={classNames('g-3', 'mb-6')}>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isValid={touched.name && !errors.name}
                            isInvalid={touched.name && !!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.name}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.email}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Contact</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isValid={touched.phone && !errors.phone}
                            isInvalid={touched.phone && !!errors.phone}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.phone}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={6} md={6}>
                        <Form.Group className="mb-2">
                          <Form.Label>Postcode</Form.Label>
                          <Form.Control
                            type="text"
                            name="postcode"
                            value={values.postcode}
                            onChange={handleChange}
                            isValid={touched.postcode && !errors.postcode}
                            isInvalid={touched.postcode && !!errors.postcode}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.postcode}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={8} md={8}>
                        <Row className={classNames('g-3', 'mb-6')}>
                          <Col sm={6} md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Password</Form.Label>
                              <Form.Control
                                type="text"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                isValid={touched.password && !errors.password}
                                isInvalid={
                                  touched.password && !!errors.password
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {' '}
                                {errors.password}{' '}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3} md={3}>
                            {' '}
                            <br />
                            <Button
                              variant="outline-primary"
                              className="me-2 px-6"
                              onClick={() => generatePassword(12)}
                            >
                              Generate
                            </Button>{' '}
                          </Col>
                          <Col sm={6} md={6}>
                            <Form.Group className="mb-2">
                              <Form.Label>Code</Form.Label>
                              <Form.Control
                                type="text"
                                name="code"
                                placeholder="Code"
                                value={values.code}
                                onChange={handleChange}
                                isValid={touched.code && !errors.code}
                                isInvalid={touched.code && !!errors.code}
                              />
                              <Form.Control.Feedback type="invalid">
                                {' '}
                                {errors.code}{' '}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3} md={3}>
                            {' '}
                            <br />
                            <Button
                              variant="outline-primary"
                              className="me-2 px-6"
                              onClick={() => generateCode(6)}
                            >
                              Generate
                            </Button>{' '}
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={3} md={3}>
                        <a href={qrCodeImage} download={values.code}>
                          <img src={qrCodeImage} alt="" className="w-50" />
                        </a>
                      </Col>

                      <Col sm={12} md={12}>
                        <Form.Group className="mb-2">
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            rows={4}
                            isValid={touched.address && !errors.address}
                            isInvalid={touched.address && !!errors.address}
                          />
                          <Form.Control.Feedback type="invalid">
                            {' '}
                            {errors.address}{' '}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <div className="text-end">
                        <Button
                          variant="phoenix-primary"
                          className="me-2 px-6"
                          onClick={navigateBack}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" variant="primary">
                          Create
                        </Button>
                      </div>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SAAddClinic;
