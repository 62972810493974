import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { useState } from 'react';
import axios from 'axios';
import { errorHandler } from 'helpers/response-utils';
import { Dropdown, Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const patientTableColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
    cell: ({ row: { original } }) => {
      const { firstname, lastname, id } = original;
      return (
        <div className="d-flex align-items-center">
          <div>
            <Link to={'/clinic/patient/edit/' + id} className="fs-8 fw-bold">
              {firstname} {lastname}
            </Link>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 border-start border-end border-translucent ps-0'
      },
      cellProps: {
        className:
          'ps-4 border-start border-end border-translucent white-space-nowrap ps-0'
      }
    }
  },
  {
    accessorKey: 'fileID',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-subtle rounded me-2">
            <FeatherIcon icon="file" size={16} className="text-primary-dark" />
          </div>
          <span>File no</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { fileID } = original;
      return <>{fileID}</>;
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'email',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-subtle rounded me-2">
            <FeatherIcon icon="mail" size={16} className="text-success-dark" />
          </div>
          <span>Email</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link className="text-body-highlight" to={`mailto:${email}`}>
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-subtle rounded me-2">
            <FeatherIcon icon="phone" size={16} className="text-primary-dark" />
          </div>
          <span>Phone</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { phone } = original;
      return (
        <Link className="text-body-highlight" to={`tel:${phone}`}>
          {phone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'createdAt',
    header: 'Create date',
    cell: ({ row: { original } }) => original.createdAt,
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-body-tertiary'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      const token = localStorage.getItem('token');
      const nav = useNavigate();
      const deleteReacod = async (id: number) => {
        const response = await axios.delete(
          `${process.env.REACT_APP_ENDPOINT}/clinic/patient/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.data['status'] == false) {
          errorHandler(response.data);
          return false;
        }
        handleClose();
        window.location.reload();
      };

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      const navigateToEdit = (id: number) => {
        nav('/clinic/patient/edit/' + id);
      };
      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item onClick={() => navigateToEdit(original.id)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={handleShow} className="text-danger">
                Remove
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this patient?
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => deleteReacod(original.id)}
              >
                Yes delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: 'auto' },
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      },
      cellProps: {
        className: 'text-end border-end pe-4 ps-4 border-translucent'
      }
    }
  }
];

const PatientTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default PatientTable;
