import axios from 'axios';
import classNames from 'classnames';
import Button from 'components/base/Button';
import * as formik from 'formik';
import { errorHandler } from 'helpers/response-utils';
import { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { goalsValidationSchma } from 'static/Patient';
import * as yup from 'yup';

const UploadGoals = () => {
  const navigate = useNavigate();
  const { Formik, useFormik } = formik;
  const { id } = useParams(); // Assuming you're using React Router for navigation
  const token = localStorage.getItem('token');

  const [goals, setGoals] = useState({
    title: '',
    goals: ''
  });
  const [goalsRequestMode, setGoalsRequestMode] = useState('');
  const schema = yup.object().shape(goalsValidationSchma);

  useEffect(() => {
    getGoals();
  }, []);

  const getGoals = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/clinic/goals/patient/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status === false) {
        errorHandler(response.data);
        return;
      }
      const data = response.data.data.goals;
      if (response.data.data.goals != null) {
        setGoals({
          title: data.title,
          goals: data.goals
        });
        setGoalsRequestMode('update');
      } else {
        setGoalsRequestMode('create');
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const onSubmit = async (values: any) => {
    if (goalsRequestMode == 'update') {
      updateGoals(values);
    }

    if (goalsRequestMode == 'create') {
      createGoals(values);
    }
  };

  const updateGoals = async (values: any) => {
    const URL = `${process.env.REACT_APP_ENDPOINT}/clinic/goals/patient/${id}`;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const data = {
      title: values.title,
      goals: values.goals
    };

    try {
      const response = await axios.put(URL, data, header);

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const createGoals = async (values: any) => {
    const URL = `${process.env.REACT_APP_ENDPOINT}/clinic/goals`;
    const header = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const data = {
      title: values.title,
      goals: values.goals,
      patient: id
    };

    try {
      const response = await axios.post(URL, data, header);

      if (response.data.status === false) {
        toast.error(response.data.error.message);
        return;
      }

      toast(response.data.data.message);
      navigateBack();
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const navigateBack = () => {
    navigate('/clinic/patient/list');
  };

  return (
    <Row>
      <Col xl={12}>
        <Formik
          validationSchema={schema}
          onSubmit={values => onSubmit(values)}
          initialValues={{
            title: '',
            goals: ''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setValues
          }) => {
            useEffect(() => {
              setValues(goals);
            }, [goals]);
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Row className={classNames('g-3', 'mb-6')}>
                  <Col sm={7} md={7}>
                    <Form.Group className="mb-2">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                        isInvalid={touched.title && !!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.title}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={7} md={7}>
                    <Form.Group className="mb-2">
                      <Form.Label>Goals</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="goals"
                        value={values.goals}
                        onChange={handleChange}
                        rows={4}
                        isValid={touched.goals && !errors.goals}
                        isInvalid={touched.goals && !!errors.goals}
                      />
                      <Form.Control.Feedback type="invalid">
                        {' '}
                        {errors.goals}{' '}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <div className="text-end">
                    <Button
                      variant="phoenix-primary"
                      className="me-2 px-6"
                      onClick={navigateBack}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="primary">
                      Update
                    </Button>
                  </div>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export default UploadGoals;
